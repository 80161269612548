import styles from './badge.module.scss'

export type BadgeProps = {
  label: string
  type?: 'electric' | 'stock-status' | 'standard' | 'loco-day'
  className?: string
}

export const Badge = ({ label, className = '', type = 'standard' }: BadgeProps) => {
  return (
    <span
      className={`${styles.main} ${className}`}
      data-badge-type={type}>
      {label}
    </span>
  )
}
