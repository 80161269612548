import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import styles from './vehicle-card.module.scss'
import { Badge } from '@/components/styleguide/Badge'
import { LocoScore } from '@/components/styleguide/LocoScore'
import { VehicleImage, VehicleImageWithGallery } from '@/components/styleguide/VehicleImage'
import { FILTER_LEASE_TYPES_PERSONAL, FILTER_STOCK_STATUS_IN_STOCK } from '@/lib/constants'
import { getPcpPricesForPersonalService } from '@/lib/services/searchService'
import {
  addVatToPrice,
  formatPrice,
  formatWithCommas,
  fuelTypeAbbreviation,
  getTermsAsYearsName,
} from '@/lib/utilities/search'
import { generateVehicleLink } from '@/lib/utilities/vehicle'
import { selectGrouping } from '@/store/selectors/search'
import { Size } from '@/types/system'
import { Vehicle, VehiclePrice } from '@/types/vehicle'

const Icon = dynamic(() => import('@/components/styleguide/Icon'))

export type VehicleCardProps = {
  type: 'list' | 'carousel' | 'grid'
  deal: {
    vehicle: Vehicle
    vehiclePrice: VehiclePrice
  }
  isPriority?: boolean
}
export const VehicleCard = ({ deal, type = 'list', isPriority = false }: VehicleCardProps) => {
  const [includeVat, setIncludeVat] = useState<boolean>(
    deal.vehiclePrice.leaseType === FILTER_LEASE_TYPES_PERSONAL,
  )
  const [pcpDifference, setPcpDifference] = useState<number>(0)
  const [showPcpDifference, setShowPcpDifference] = useState<boolean>(false)
  const [isLocoDayDeal] = useState<boolean>(false)
  const grouping = useSelector(selectGrouping)

  const vehicleGalleryImages = deal.vehicle.gallery?.images

  useEffect(() => {
    setIncludeVat(deal.vehiclePrice.leaseType === FILTER_LEASE_TYPES_PERSONAL)
  }, [deal])

  useEffect(() => {
    setIncludeVat(deal.vehiclePrice.leaseType === FILTER_LEASE_TYPES_PERSONAL)
  }, [deal])

  useEffect(() => {
    const controller = new AbortController()
    if (deal && includeVat) {
      getPcpPricesForPersonalService({
        term: deal.vehiclePrice.term,
        mileage: deal.vehiclePrice.mileage,
        initialPaymentInMonths: deal.vehiclePrice.initialPaymentInMonths,
        vehicleId: deal.vehicle.id,
      })
        .then(res => {
          const difference = addVatToPrice(res.monthlyPayment - deal.vehiclePrice.monthlyPayment)
          setPcpDifference(parseFloat(difference))
          if (parseFloat(difference) > 0) {
            setShowPcpDifference(true)
          }
        })
        .catch(() => {
          setPcpDifference(0)
        })
    }
    return () => {
      controller.abort()
    }
  }, [includeVat, deal])

  const displayPrice = includeVat
    ? formatPrice(addVatToPrice(deal.vehiclePrice.monthlyPayment))
    : formatPrice(deal.vehiclePrice.monthlyPayment)

  return (
    <Link
      href={generateVehicleLink(deal.vehiclePrice, 'config')}
      passHref>
      <a
        onClick={e => {
          // if user is clicking on navigation button, don't go through to config page
          if (e.target.nodeName === 'BUTTON') {
            e.preventDefault()
          }
        }}
        data-type={type}
        className={styles.container}
        title={`${deal.vehicle.manufacturerName} ${deal.vehicle.modelName}${grouping === 1 ? ' ' + deal.vehicle.derivativeName : ''}`}>
        <div className={styles.main}>
          <div className={styles['image-container']}>
            {vehicleGalleryImages ? (
              <VehicleImageWithGallery
                images={vehicleGalleryImages || []}
                alt={`${deal.vehicle.manufacturerName} ${deal.vehicle.modelName}`}
                height={234}
                type="BROWSE"
                width={352}
              />
            ) : (
              <VehicleImage
                imagePath={''}
                backupImagePath={deal.vehicle.images.profile}
                alt={`${deal.vehicle.manufacturerName} ${deal.vehicle.modelName}`}
                height={234}
                type="BROWSE"
                width={352}
                priority={isPriority}
              />
            )}
            <LocoScore
              rating={deal.vehiclePrice.rating}
              className={styles.score}
            />
          </div>
          <div className={styles['detail-container']}>
            <div>
              {(deal.vehiclePrice.stockStatus === FILTER_STOCK_STATUS_IN_STOCK ||
                deal.vehicle.fuelType === 5 ||
                isLocoDayDeal) && (
                <div className={styles.badges}>
                  {deal.vehiclePrice.stockStatus === FILTER_STOCK_STATUS_IN_STOCK && (
                    <Badge
                      label="In stock"
                      type="stock-status"
                    />
                  )}
                  {deal.vehicle.fuelType === 5 && (
                    <Badge
                      label="Electric"
                      type="electric"
                    />
                  )}
                  {isLocoDayDeal && (
                    <Badge
                      label="Locoday deal"
                      type="loco-day"
                    />
                  )}
                </div>
              )}
              <h3 className={styles['make-model']}>
                {deal.vehicle.manufacturerName} {deal.vehicle.modelName}
              </h3>
              {grouping === 1 && (
                <p
                  className={styles.derivative}
                  data-is-desktop-only={true}>
                  {deal.vehicle.derivativeName}
                </p>
              )}
              <ul
                className={styles.attributes}
                data-is-desktop-only={true}>
                <li>
                  <Icon
                    size={Size.Small}
                    name="User"
                  />{' '}
                  {deal.vehicle.seats}
                </li>
                <li>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3 11L11 3H21V11M3 11V18C3 18.5304 3.21071 19.0391 3.58579 19.4142C3.96086 19.7893 4.46957 20 5 20H19C19.5304 20 20.0391 19.7893 20.4142 19.4142C20.7893 19.0391 21 18.5304 21 18V11M3 11H21"
                      stroke="#07081A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18 14H14"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                  {deal.vehicle.doors}
                </li>
                <li>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5 9V12M5 15V12M5 12H12.25M12.25 12H19V9M12.25 12V15M12.25 12V9"
                      stroke="#07081A"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <circle
                      cx="5"
                      cy="5"
                      r="2"
                      fill="black"
                    />
                    <circle
                      cx="5"
                      cy="19"
                      r="2"
                      fill="black"
                    />
                    <circle
                      cx="12"
                      cy="5"
                      r="2"
                      fill="black"
                    />
                    <circle
                      cx="12"
                      cy="19"
                      r="2"
                      fill="black"
                    />
                    <circle
                      cx="19"
                      cy="5"
                      r="2"
                      fill="black"
                    />
                  </svg>
                  {deal.vehicle.transmissionTypeName}
                </li>
                <li>
                  <Icon
                    size={Size.Small}
                    name={
                      deal.vehicle.fuelType === 1 || deal.vehicle.fuelType === 2
                        ? 'Fuel'
                        : deal.vehicle.fuelType === 5
                          ? 'Zap'
                          : 'Plug'
                    }
                  />
                  {deal.vehicle.fuelType === 5
                    ? 'Fully electric'
                    : fuelTypeAbbreviation(deal.vehicle.fuelTypeName)}
                </li>
              </ul>
            </div>
            <div>
              {grouping === 1 && (
                <p
                  className={styles['body-type']}
                  data-is-mobile-only={true}>
                  {deal.vehicle.derivativeName}
                </p>
              )}
              <p className={styles['body-type']}>
                <span data-is-desktop-only={true}>
                  Body type <br />
                </span>
                {deal.vehicle.bodyStyleName}
              </p>
              <p className={styles['lease-profile']}>
                {getTermsAsYearsName(deal.vehiclePrice.term)} y
                <span className={styles['hidden-extensions']}>ea</span>r
                {deal.vehiclePrice.term !== 12 ? 's + ' : ' + '}
                {deal.vehiclePrice.initialPaymentInMonths} mo
                <span className={styles['hidden-extensions']}>nth</span>
                {deal.vehiclePrice.initialPaymentInMonths > 1 ? 's' : ''} upfront
                <span> &middot; </span>
                {formatWithCommas(deal.vehiclePrice.mileage)} miles
              </p>
              <p
                className={styles['monthly-price']}
                data-is-mobile-only={true}>
                <span>{displayPrice}</span> per month{' '}
                {!includeVat && <span className={styles['small-text']}>(ex VAT)</span>}
              </p>
              {showPcpDifference && (
                <p
                  className={styles['pcp-saving']}
                  data-is-mobile-only={true}>
                  Save {formatPrice(pcpDifference)} per month
                </p>
              )}
            </div>
          </div>
          <div className={styles['summary-container']}>
            <LocoScore
              rating={deal.vehiclePrice.rating}
              isInline
              className={styles.score}
            />
            <div className={styles.action}>
              <p className={styles['monthly-price']}>
                <span>{displayPrice}</span> per month{' '}
                {!includeVat && <span className={styles['small-text']}>(ex VAT)</span>}
              </p>
              {showPcpDifference && (
                <p className={styles['pcp-saving']}>Save {formatPrice(pcpDifference)}/pm vs PCP</p>
              )}
              <span className={styles.configure}>Configure</span>
            </div>
          </div>
        </div>
      </a>
    </Link>
  )
}
